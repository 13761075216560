import React from 'react';
import {Route} from "react-router";

import Compare from "../components/functies/Compare";

const CompareScene = () => {
    return (
        <main>
            <Route path="/vergelijken" component={Compare} />
        </main>
    );
};

export default CompareScene;