import React, {Component, Fragment} from 'react';
import {Redirect} from "react-router";

import HelperFunctions from "../global/HelperFunctions";
import Api from "../api/Api";
import config from "../../config/config";
import Language from "../../language/Language";
import CollapsableInfoContainer, {
    AlgemeneInfoContainer, FactorContainer,
    ResultaatgebiedContainer
} from "./misc/CollapsableInfoContainer";
import LoadingSpinner from "../global/LoadingSpinner";

export default class View extends Component {
    constructor(props) {
        super(props);
        document.title = config.metaTitle + ' | ' + Language.getTranslation('viewPageTitle');

        const schaalId = HelperFunctions.getParameterFromUrl('bekijken');
        this.state = {
            data: {},
            schaalId,
            isLoading: true,
            redirect: schaalId === undefined,
        };
    }

    componentDidMount() {
        Api.getSchaal(this.state.schaalId)
            .then(data => {
                this.setState({
                    data,
                    isLoading: false,
                });
            })
            .catch(error => console.log('api error', error));
    }

    render() {
        if (this.state.redirect) {
            return <Redirect to="/" />
        }

        if (!this.state.isLoading) {
            const {factoren, factorHeaders, familie, functie, fuwaprov, korteTypering, number, resultaatgebieden} = this.state.data;
            const otherColors = ['lightblue', 'yellow', 'orange'].filter(val => (val !== familie.color));

            return (
                <Fragment>
                    <div className="container pt-5 pb-3">
                        <h1 className={'mb-4 p-3 text-uppercase bg-' + familie.color}>
                            <span className="other-familie-colors">
                                {otherColors.map((otherColor, index) => <span className={'bg-' + otherColor}  key={index}/>)}
                            </span>
                            {familie.title} | {functie.reeksTitle}
                        </h1>
                        <p className="lead"><strong>{Language.getTranslation('schaal')} {number}</strong></p>
                        <p><strong>{Language.getTranslation('functieTypering')}</strong><br />{korteTypering}</p>
                    </div>

                    <CollapsableInfoContainer bgColor="light" htmlId="jsFunctieInfoAlgemeen" opened={true} title={Language.getTranslation('algemeneInformatie')}>
                        <AlgemeneInfoContainer functie={functie} familie={familie} />
                    </CollapsableInfoContainer>

                    <CollapsableInfoContainer bgColor="white" htmlId="jsFunctieResultaatgebieden" title={Language.getTranslation('resultaatgebieden')}>
                        <ResultaatgebiedContainer data={resultaatgebieden} />
                    </CollapsableInfoContainer>

                    <CollapsableInfoContainer bgColor="light" htmlId="jsFunctieFactoren" title={Language.getTranslation('factoren')}>
                        <FactorContainer data={factoren} headers={factorHeaders} hideTextDifferences={true} />
                    </CollapsableInfoContainer>

                    <CollapsableInfoContainer bgColor="white" htmlId="jsFunctieFuwaprov" title={Language.getTranslation('fuwaprov')}>
                        <p>{fuwaprov}</p>
                    </CollapsableInfoContainer>
                </Fragment>
            );
        } else {
            return <LoadingSpinner loadingMessage={true} />
        }
    }
}