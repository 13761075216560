import React, {Component} from 'react';
import PropTypes from 'prop-types';

import history from '../global/History';
import Api from "../api/Api";
import CustomSelect from "../global/CustomSelect";
import Language from "../../language/Language";
import HelperFunctions from "../global/HelperFunctions";
import LoadingSpinner from "../global/LoadingSpinner";
import {Redirect} from "react-router";

const constants = {
    functie: 'Functie',
    functies: 'Functies',
    prefixEnd: 'end',
    prefixStart: 'start',
    schaal: 'Schaal',
    schalen: 'Schalen'
};

export default class CompareForm extends Component {
    constructor(props) {
        super(props);
        this.handleSelectChange = this.handleSelectChange.bind(this);
        this.setEndFunctie = this.setEndFunctie.bind(this);
        this.setStartFunctie = this.setStartFunctie.bind(this);
        this.setEndSchaal = this.setEndSchaal.bind(this);
        this.setStartSchaal = this.setStartSchaal.bind(this);
        this.setUrlOrRedirect = this.setUrlOrRedirect.bind(this);

        this.state = {
            data: {
                startFuncties: [],
                startSchalen: [],
                endFuncties: [],
                endSchalen: [],
            },
            isLoading: true,
            redirect: false,
            selected: {
                startFunctie: null,
                startSchaal: null,
                endFunctie: null,
                endSchaal: null,
            },
        };

        this.baseUrl = props.baseUrl !== undefined ? props.baseUrl : '/vergelijken/';
        this.apiData = [];
    }

    componentDidMount() {
        Api.getAllFamilies()
            .then(result => {
                const selectedStart = HelperFunctions.getTwoParametersFromUrl('vergelijken');
                const selectedEnd = HelperFunctions.getTwoParametersFromUrl('to');
                const selectedFromUrl = {
                    startFunctie: parseInt(selectedStart[0]),
                    startSchaal: parseInt(selectedStart[1]),
                    endFunctie: parseInt(selectedEnd[0]),
                    endSchaal: parseInt(selectedEnd[1]),
                };
                const selected = this.state.selected;

                let functies = [];
                let apiData = [];
                let startSchalen = [];
                let endSchalen = [];

                result.length > 0 && result.forEach(familie => {
                    let options = [];
                    familie.functies.length > 0 && familie.functies.forEach(functie => {
                        const functieOption = {
                            label: functie.title,
                            value: functie.id,
                        };
                        options.push(functieOption);

                        // Build schalen options array for dropdowns and match selected schalen from url
                        let schalen = [];
                        functie.schalen.length > 0 && functie.schalen.forEach(schaal => {
                            const schaalOption = {
                                label: constants.schaal + ' ' + schaal.number,
                                value: schaal.id,
                            };

                            schaal.active && schalen.push(schaalOption);

                            if (schaal.id === selectedFromUrl.startSchaal) {
                                selected.startSchaal = schaalOption;
                            }
                            if (schaal.id === selectedFromUrl.endSchaal) {
                                selected.endSchaal = schaalOption;
                            }
                        });

                        // Match selected functies from url and set schalen array for dropdown
                        if (functie.id === selectedFromUrl.startFunctie) {
                            selected.startFunctie = functieOption;
                            startSchalen = schalen;
                        }
                        if (functie.id === selectedFromUrl.endFunctie) {
                            selected.endFunctie = functieOption;
                            endSchalen = schalen;
                        }

                        // Build functies url
                        apiData.push({
                            id: functie.id,
                            title: functie.title,
                            schalen,
                        });
                    });

                    // Build functies array for dropdowns
                    functies.push({
                        label: familie.title,
                        options,
                    });
                });
                this.apiData = apiData;

                this.setState({
                    data: {
                        startFuncties: functies,
                        startSchalen,
                        endFuncties: functies,
                        endSchalen,
                    },
                    isLoading: false,
                    selected,
                });
            })
            .catch(error => console.log('api error', error));
    }

    handleSelectChange(prefix, selector, selectedOption) {
        const {data, selected} = this.state;

        selected[prefix + selector] = selectedOption;

        if (selector === constants.functie) {
            if (this.state.selected[prefix + selector] !== selectedOption.value) {
                const functie = HelperFunctions.getItemFromArrayByValue(this.apiData, 'id', selectedOption.value);
                if (functie.schalen !== undefined && functie.schalen.length > 0) {
                    data[prefix + constants.schalen] = functie.schalen;
                }

                // Reset selected schaal on functie change
                selected[prefix + constants.schaal] = null;
            }
        }

        this.setState({
            data,
            selected,
        }, () => {
            this.setUrlOrRedirect();
        });
    }

    setEndFunctie(e) {
        this.handleSelectChange(constants.prefixEnd, constants.functie, e);
    }

    setStartFunctie(e) {
        this.handleSelectChange(constants.prefixStart, constants.functie, e);
    }

    setEndSchaal(e) {
        this.handleSelectChange(constants.prefixEnd, constants.schaal, e);
    }

    setStartSchaal(e) {
        this.handleSelectChange(constants.prefixStart, constants.schaal, e);
    }

    setUrlOrRedirect() {
        const {startFunctie, startSchaal, endFunctie, endSchaal} = this.state.selected;
        const url = (startFunctie !== null ? startFunctie.value : 0)
            + '/' + (startSchaal !== null ? startSchaal.value : 0)
            + '/to/' + (endFunctie !== null ? endFunctie.value : 0)
            + '/' + (endSchaal !== null ? endSchaal.value : 0);

        if (this.props.redirectAfterFormSubmit && this.props.redirectUrl !== undefined) {
            // Redirect if all options are selected
            if (startFunctie !== null && startSchaal !== null && endFunctie !== null && endSchaal !== null) {
                this.setState({
                    redirect: this.props.redirectUrl + '/' + url,
                });
            }
        } else {
            // Update current url
            history.push(this.baseUrl + url);
        }
    }

    render() {
        if (this.state.redirect) {
            return <Redirect to={this.state.redirect} />
        }

        return (
            <div className="d-print-none card card-body pt-5 pb-5 bg-light border-0">
                <div className="container">
                    {this.props.heading === 1 ? (
                        <h1 className="card-title">{Language.getTranslation('compareFormTitle')}</h1>
                    ) : (
                        <h2 className="card-title">{Language.getTranslation('compareFormTitle')}</h2>
                    )}
                    <div className="row">
                        <p className="col-10 col-md-8">{Language.getTranslation('compareFormDescription')}</p>
                    </div>

                    {!this.state.isLoading ? (
                        <div className="row gutter-2">
                            <Column functies={this.state.data.startFuncties} onChangeFunctie={this.setStartFunctie} onChangeSchaal={this.setStartSchaal} schalen={this.state.data.startSchalen} selectedFunctie={this.state.selected.startFunctie} selectedSchaal={this.state.selected.startSchaal} />
                            <Column functies={this.state.data.endFuncties} onChangeFunctie={this.setEndFunctie} onChangeSchaal={this.setEndSchaal} schalen={this.state.data.endSchalen} selectedFunctie={this.state.selected.endFunctie} selectedSchaal={this.state.selected.endSchaal} />
                        </div>
                    ) : (
                        <LoadingSpinner inline={true} loadingMessage={true} />
                    )}
                </div>
            </div>
        );
    }
}

const Column = props => {
    const {functies, onChangeFunctie, onChangeSchaal, schalen, selectedFunctie, selectedSchaal} = props;
    return (
        <div className="col-md-6">
            <div className="row">
                <div className="col-md-8">
                    <CustomSelect onChange={onChangeFunctie} options={functies} placeholder={Language.getTranslation('selectFunctiePlaceholder')} value={selectedFunctie} />
                </div>
                <div className="col-md-4">
                    <CustomSelect isDisabled={schalen.length < 1} onChange={onChangeSchaal} options={schalen} placeholder={Language.getTranslation('selectSchaalPlaceholder')} value={selectedSchaal} />
                </div>
            </div>
        </div>
    );
};

CompareForm.propTypes = {
    heading: PropTypes.number,
    redirectAfterFormSubmit: PropTypes.bool.isRequired,
    redirectUrl: PropTypes.string,
};
Column.propTypes = {
    functies: PropTypes.array.isRequired,
    onChangeFunctie: PropTypes.func.isRequired,
    onChangeSchaal: PropTypes.func.isRequired,
    schalen: PropTypes.array.isRequired,
    selectedFunctie: PropTypes.any,
    selectedSchaal: PropTypes.any,
};