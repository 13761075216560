import React from 'react';
import PropTypes from 'prop-types';
import {Route, Switch} from 'react-router-dom';
import {Redirect} from "react-router";

import Functies from "../scenes/Functies";
import Compare from "../scenes/Compare";
import ContentPage from "./contentPages/ContentPage";

const Routes = props => {
    const {contentPages} = props;
    return (
        <Switch>
            <Route path="/" exact strict render={() => <Redirect to="/functies" />} />
            <Route path="/functies" component={Functies} />
            <Route path="/vergelijken" component={Compare} />
            {(contentPages !== undefined && contentPages.length > 0) && <Route path="/:pageName" render={routerProps => <ContentPage contentPages={contentPages} routerProps={routerProps} />} />}
        </Switch>
    );
};

export default Routes;

Routes.propTypes = {
    contentPages: PropTypes.array,
};