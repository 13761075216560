import React from 'react';
import PropTypes from 'prop-types';
import {Navbar as BsNavbar, Nav, Button} from 'react-bootstrap';
import {Route, NavLink, Link} from 'react-router-dom';

import Language from "../../language/Language";

const Navbar = props => {
    // const {contentPages} = props;
    let contentPages = undefined;
    if (props.contentPages !== undefined) {
        contentPages = Array.from(props.contentPages);
    }
    return (
        <BsNavbar bg="navy" variant="dark" expand="lg">
            <div className="container">
                <Link className="navbar-brand" to="/functies">{Language.getTranslation('brand')}</Link>
                <BsNavbar.Toggle aria-controls="jsNavbarNav" />
                <BsNavbar.Collapse id="jsNavbarNav">
                    <Nav className="mr-auto">
                        <NavItem label="home" to="/functies" />
                        <NavItem label="vergelijken" to="/vergelijken" />
                        {(contentPages !== undefined && contentPages.length > 0) && contentPages.map(page => <NavItem label={page.title} to={'/' + page.slug} slug={page.slug} key={page.id} />)}
                    </Nav>
                    <Nav className="d-none d-lg-flex">
                        <li className="nav-item">
                            <Button variant="nav-link" title={Language.getTranslation('printPage', 'navbar')} onClick={window.print}>
                                <span className="fas fa-print" /><span className="d-navbar-expanded-lg ml-2">{Language.getTranslation('printPage', 'navbar')}</span>
                            </Button>
                        </li>
                    </Nav>
                </BsNavbar.Collapse>
            </div>
        </BsNavbar>
    );
}

const NavItem = props => {
    const {exact, label, slug, to} = props;
    const translation = slug !== undefined && slug !== '' ? slug : label;
    return (
        <Route path={to} exact={exact}>
            {({match}) => <li className={'nav-item ' + (match ? 'active' : '')}>
                <NavLink to={to} className="nav-link">
                    {Language.getTranslation(translation, 'navbar')}
                </NavLink>
            </li>}
        </Route>
    );
};

export default Navbar;

Navbar.propTypes = {
    contentPages: PropTypes.array,
};
NavItem.propTypes = {
    exact: PropTypes.any,
    label: PropTypes.string.isRequired,
    slug: PropTypes.string,
    to: PropTypes.string.isRequired,
};