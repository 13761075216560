import React, {Fragment} from 'react';
import {Redirect, Route} from "react-router";

import Index from "../components/functies/Index";
import View from "../components/functies/View";
import Compare from "../components/functies/Compare";
import CompareForm from "../components/functies/CompareForm";
import Language from "../language/Language";

const Functies = () => {
    return (
        <main>
            <Route path="/" exact strict render={() => <Redirect to="/functies" />} />
            <Route path="/functies" exact component={IndexScene} />
            <Route path="/functies/bekijken/" component={ViewScene} />
            <Route path="/functies/bekijken" exact strict render={() => <Redirect to="/functies" />} />
            <Route path="/functies/vergelijken" component={CompareScene} />
        </main>
    );
};

const IndexScene = () => {
    return (
        <Fragment>
            <div className="container py-5">
                <h1>{Language.getTranslation('indexPageTitle')}</h1>
                <p>{Language.getTranslation('indexPageSubtitle')}</p>
                <Index />
            </div>
            <CompareForm heading={2} redirectAfterFormSubmit={true} redirectUrl="/vergelijken" />
        </Fragment>
    );
};

const ViewScene = () => {
    return <View />
};

const CompareScene = () => {
    return (
        <Compare />
    );
};

export default Functies;