import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Link} from "react-router-dom";

import Api from '../api/Api';
import config from "../../config/config";
import Language from "../../language/Language";
import LoadingSpinner from "../global/LoadingSpinner";

export default class Index extends Component {
    constructor(props) {
        super(props);
        document.title = config.metaTitle + ' | ' + Language.getTranslation('indexPageMetaTitle');

        this.state = {
            data: [],
            isLoading: true,
        };
    }

    componentDidMount() {
        Api.getAllFamilies()
            .then(data => {
                this.setState({
                    data,
                    isLoading: false,
                });
            })
            .catch(error => console.log('api error', error));
    }

    render() {
        if (!this.state.isLoading) {
            return (
                <>
                    <div className="row mt-4 mb-0 mx-lg-0 d-none d-lg-flex border-between-lg border-between-2">
                        {this.state.data.map(family => <div className={'col-lg px-lg-0 bg-' + family.color} key={family.id}>
                            <h2 className="m-lg-0 py-lg-2 px-lg-3 text-center text-uppercase">{family.title}</h2>
                        </div>)}
                    </div>
                    <div className="row mx-lg-0 border-between-lg border-between-2">
                        {this.state.data.map(family => <FunctiefamilieIndex {...family} key={family.id} />)}
                    </div>
                </>
            );
        } else {
            return <LoadingSpinner loadingMessage={true} />
        }
    }
}

const FunctiefamilieIndex = props => {
    const {color, functies, title} = props;
    return (
        <div className="col-lg mb-3 px-md-0">
            <h2 className={'m-0 py-2 d-lg-none text-center text-uppercase bg-' + color}>{title}</h2>

            {(functies !== undefined && functies.length > 0) ? (
                <>
                    <div className={'row mx-0 d-none d-lg-flex border-between bg-' + color + '-light'}>
                        {functies.map(functie => <div className="col-6 px-0" key={functie.id}>
                            <h3 className="m-0 py-2 px-3 text-center">{functie.title}</h3>
                        </div>)}
                    </div>
                    <div className="row mx-0 border-between">
                        {functies.map(functie => <Functie {...functie} color={color} key={functie.id} />)}
                    </div>
                </>
            ) : (
                <div className={'row mx-0 d-none d-lg-flex border-between bg-' + color + '-light'}>
                    <h3 className={'m-0 py-1 px-3 text-center min-height-45 bg-' + color + '-light'}>{Language.getTranslation('functieReeksenNoResults')}</h3>
                </div>
            )}
        </div>
    );
};

const Functie = props => {
    const {color, schalen, title} = props;
    return (
        <div className="col-6 px-0">
            <h3 className={'m-0 py-1 px-3 d-md-none text-center min-height-45 bg-' + color + '-light'}>{title}</h3>

            {(schalen !== undefined && schalen.length > 0) ? (
                schalen.map(schaal => <Schaal {...schaal} color={color} key={schaal.uniqueId} />)
            ) : (
                <Schaal color={color} text="Geen functies" />
            )}
        </div>
    );
};

const Schaal = props => {
    const { active, color, id, number, text } = props;
    if (active) {
        return <Link to={'/functies/bekijken/' + id} className="m-0 py-1 px-3 functie-list-item active">Schaal {number} <span className="fa fa-angle-right functie-list-item-icon" /></Link>
    } else {
        return <p className={'m-0 py-1 px-3 functie-list-item bg-' + color + '-extralight'}>{text !== undefined && text}</p>
    }
};

FunctiefamilieIndex.propTypes = {
    color: PropTypes.string.isRequired,
    functiereeksen: PropTypes.array,
    id: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
};
Functie.propTypes = {
    color: PropTypes.string.isRequired,
    functies: PropTypes.array,
    id: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
};
Schaal.propTypes = {
    active: PropTypes.bool,
    color: PropTypes.string,
    id: PropTypes.number,
    number: PropTypes.number,
    text: PropTypes.string,
};