import React from 'react';
import PropTypes from 'prop-types';
import Select from "react-select";

import * as colors from "../../css/export-theme-for-react.scss";
import Language from "../../language/Language";

const customStyles = {
    control: (provided, {menuIsOpen}) => ({ ...provided, borderColor: menuIsOpen ? colors.teal : 'transparent', borderRadius: 0, boxShadow: menuIsOpen ? '0 0 0 1px ' + colors.teal : '', '&:hover': { borderColor: colors.teal, }, }),
    dropdownIndicator: (provided) => ({ ...provided, color: colors.navy, '&:hover': { color: colors.navy, }, }),
    indicatorsContainer: (provided) => ({ ...provided, backgroundColor: colors.teal, color: colors.navy, '&:hover': { color: colors.navy, }, }),
    indicatorSeparator: () => ({ display: 'none', }),
    menu: (provided) => ({ ...provided, borderRadius: 0, }),
    option: (provided, {isDisabled, isFocused, isSelected}) => ({
        ...provided,
        backgroundColor: isDisabled
            ? colors.light
            : isSelected
                ? colors.teal
                : isFocused
                    ? colors['teal-dark']
                    : null,
        color: isDisabled
            ? colors.dark
            : isSelected
            ? colors.white
            : isFocused
                ? colors.white
                : null,
        '&:active': {
            backgroundColor: isDisabled
                ? null
                : isSelected
                    ? colors.teal
                    : isFocused
                        ? colors['teal-dark']
                        : null,
        },
    }),
};

const CustomSelect = props => {
    const placeholder = props.placeholder !== undefined ? props.placeholder : Language.getTranslation('selectItem');
    return (
        <Select className="react-select-container" classNamePrefix="react-select" menuPlacement="auto" placeholder={placeholder} styles={customStyles} {...props} />
    );
};

export default CustomSelect;

CustomSelect.propTypes = {
    isDisabled: PropTypes.bool,
    onChange: PropTypes.func,
    options: PropTypes.array.isRequired,
    placeholder: PropTypes.string,
};