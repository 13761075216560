import axios from "axios/index";
import HelperFunctions from "../global/HelperFunctions";
import config from "../../config/config";
import constants from "../../config/constants";
import env from "../../config/env";

const ApiConnector = {
    apiDelete(apiUrl, authenticate) {
        authenticate = authenticate === undefined;
        return new Promise((resolve, reject) => {
            env.environment === constants.environment.development && console.log('apiDelete', apiUrl);
            const authToken = config.authToken;
            const options = { headers: {} };
            if (authenticate) {
                options.headers.Authorization = 'Bearer '.concat(authToken);
            }
            axios.delete(apiUrl, options)
                .then(result => {
                    if (result.status >= 200 && result.status < 300) {
                        resolve(result.data);
                    } else {
                        let error = new Error(result);
                        error.result = result;
                        throw error;
                    }
                })
                .catch(error => {
                    // Api returned an error
                    const errorCode = HelperFunctions.handleApiErrors(error);
                    const context = {errorCode: errorCode, data: error};
                    reject(context);
                });
        });
    },
    apiGet(apiUrl, authenticate) {
        authenticate = authenticate === undefined;
        return new Promise((resolve, reject) => {
            env.environment === constants.environment.development && console.log('apiGet', apiUrl);
            const authToken = config.authToken;
            const options = { headers: {} };
            if (authenticate) {
                options.headers.Authorization = 'Bearer '.concat(authToken);
            }
            axios.get(apiUrl, options)
                .then(result => {
                    if (result.status >= 200 && result.status < 300) {
                        resolve(result.data);
                    } else {
                        let error = new Error(result);
                        error.result = result;
                        throw error;
                    }
                })
                .catch(error => {
                    console.log('error', error);
                    // Api returned an error
                    const errorCode = HelperFunctions.handleApiErrors(error);
                    const context = {errorCode: errorCode, data: error};
                    reject(context);
                });
        });
    },
    apiPatch(apiUrl, data, authenticate) {
        authenticate = authenticate === undefined;
        return new Promise((resolve, reject) => {
            env.environment === constants.environment.development && console.log('apiPatch', apiUrl);
            const authToken = config.authToken;
            const headers = {};
            if (authenticate) {
                headers.headers = {Authorization: 'Bearer '.concat(authToken)};
            }
            axios.patch(apiUrl, data, headers)
                .then(result => {
                    if (result.status >= 200 && result.status < 300) {
                        resolve(result.data);
                    } else {
                        let error = new Error(result);
                        error.result = result;
                        throw error;
                    }
                })
                .catch(error => {
                    // Api returned an error
                    const errorCode = HelperFunctions.handleApiErrors(error);
                    const context = {errorCode: errorCode, data: error};
                    reject(context);
                });
        });
    },
    apiPost(apiUrl, data, authenticate) {
        authenticate = authenticate === undefined;
        return new Promise((resolve, reject) => {
            env.environment === constants.environment.development && console.log('apiPost', apiUrl);
            const authToken = config.authToken;
            const headers = {};
            if (authenticate) {
                headers.headers = {Authorization: 'Bearer '.concat(authToken)};
            }
            axios.post(apiUrl, data, headers)
                .then(result => {
                    if (result.status >= 200 && result.status < 300) {
                        resolve(result.data);
                    } else {
                        let error = new Error(result);
                        error.result = result;
                        throw error;
                    }
                })
                .catch(error => {
                    // Api returned an error
                    const errorCode = HelperFunctions.handleApiErrors(error);
                    const context = {errorCode: errorCode, data: error};
                    reject(context);
                });
        });
    },
    apiPut(apiUrl, data, authenticate) {
        authenticate = authenticate === undefined;
        data = data === undefined ? {} : data;
        return new Promise((resolve, reject) => {
            env.environment === constants.environment.development && console.log('apiPut', apiUrl);
            const authToken = config.authToken;
            const headers = {};
            if (authenticate) {
                headers.headers = {Authorization: 'Bearer '.concat(authToken)};
            }
            axios.put(apiUrl, data, headers)
                .then(result => {
                    if (result.status >= 200 && result.status < 300) {
                        resolve(result.data);
                    } else {
                        let error = new Error(result);
                        error.result = result;
                        throw error;
                    }
                })
                .catch(error => {
                    // Api returned an error
                    const errorCode = HelperFunctions.handleApiErrors(error);
                    const context = {errorCode: errorCode, data: error};
                    reject(context);
                });
        });
    },
    apiUpload(apiUrl, file) {
        return new Promise((resolve, reject) => {
            if (file === undefined) {
                reject('no file received for upload');
            }
            env.environment === constants.environment.development && console.log('apiUpload', apiUrl);
            const authToken = config.authToken;
            const headers = {
                headers: {
                    Authorization: 'Bearer '.concat(authToken),
                    'Content-Type': 'multipart/form-data',
                },
            };

            const formData = new FormData();
            formData.append('file', file);

            axios.post(apiUrl, formData, headers)
                .then(result => {
                    if (result.status >= 200 && result.status < 300) {
                        resolve(result.data);
                    } else {
                        let error = new Error(result);
                        error.result = result;
                        throw error;
                    }
                })
                .catch(error => {
                    // Api returned an error
                    const errorCode = HelperFunctions.handleApiErrors(error);
                    const context = {errorCode: errorCode, data: error};
                    reject(context);
                });
        });
    },
};

export default ApiConnector;