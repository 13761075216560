import React, {Component, Fragment, useState} from 'react';
import PropTypes from 'prop-types';
import Fade from "react-bootstrap/Fade";
import Parser from "html-react-parser";

import Language from "../../../language/Language";

const CollapsableInfoContainer = props => {
    const {bgColor, htmlId, opened, title} = props;
    const [collapsed, toggleCollapse] = useState(opened !== undefined && opened);

    return (
        <div className={'card card-body pt-4 pb-0 bg-silver-hover border-0 cursor-pointer bg-' + bgColor} onClick={() => toggleCollapse(!collapsed)} aria-controls={htmlId} aria-expanded={collapsed}>
            <div className="container">
                <span className={'d-print-none float-right fa fa-2x fa-chevron-' + (collapsed ? 'up' : 'down')} />
                <h2 className="mb-4 card-title">{title}</h2>
                <Fade in={collapsed} unmountOnExit={true}>
                    <div id={htmlId} className="d-print-none">
                        {props.children}
                    </div>
                </Fade>
                <div className="d-none d-print-block">{props.children}</div>
            </div>
        </div>
    );
};

export default CollapsableInfoContainer;

export const AlgemeneInfoContainer = props => {
    const {functie, familie} = props;
    return (
        <Fragment>
            <p><strong>{Language.getTranslation('functieFamilie')}</strong><br />{familie.description}</p>
            <p><strong>{Language.getTranslation('functieReeks')}</strong><br />{functie.reeksDescription}</p>
            <p><strong>{Language.getTranslation('functie')}</strong><br />{functie.title}</p>
            <p><strong>{Language.getTranslation('doel')}</strong><br />{functie.doel}</p>
        </Fragment>
    );
};

export const ResultaatgebiedContainer = props => {
    const {data} = props;
    if (data === undefined || data.length < 1) {
        return <p>{Language.getTranslation('factorNoResults')}</p>
    }

    return data.map(resultaatgebied => <Resultaatgebied resultaatgebied={resultaatgebied} key={resultaatgebied.id} />);
};

class Resultaatgebied extends Component {
    constructor(props) {
        super(props);
        this.toggleResultaatgebiedCollapse = this.toggleResultaatgebiedCollapse.bind(this);

        this.state = {
            collapsed: false,
        };
        this.htmlId = 'jsResultaatgebiedCollapse' + (Math.floor(Math.random() * 9999) + 1111);
    }

    toggleResultaatgebiedCollapse(e) {
        e.stopPropagation();
        this.setState({collapsed: !this.state.collapsed,});
    }

    render() {
        const {resultaatgebied} = this.props;

        return (
            <div className="mb-4 cursor-pointer text-hover-parent" onClick={this.toggleResultaatgebiedCollapse} aria-controls={this.htmlId} aria-expanded={this.state.collapsed} key={resultaatgebied.id}>
                <p className="mb-0 text-underline-parent-hover"><strong>{resultaatgebied.title}
                    <span className={'d-print-none ml-2 fa fa-sm fa-chevron-' + (this.state.collapsed ? 'up' : 'down')} /></strong>
                </p>
                <Fade in={this.state.collapsed} unmountOnExit={true}>
                    <div id={this.htmlId} className="d-print-none">
                        {resultaatgebied.description !== '' && Parser(resultaatgebied.description)}
                        <p>
                            <strong>Resultaat</strong><br />
                            {resultaatgebied.result}
                        </p>
                    </div>
                </Fade>
                <div className="d-none d-print-block">
                    {resultaatgebied.description !== '' && Parser(resultaatgebied.description)}
                    <p>
                        <strong>Resultaat</strong><br />
                        {resultaatgebied.result}
                    </p>
                </div>
            </div>
        )
    }
}

export const FactorContainer = props => {
    const {data, headers, hideTextDifferences} = props;
    if (headers === undefined || headers.length < 1) {
        return <p>{Language.getTranslation('factorNoResults')}</p>
    }

    const hasValidata = data !== undefined && data.length === headers.length;

    return headers.map((header, index) => <div className={hideTextDifferences ? 'hide-bold-text' : ''} key={header.id}>
        <p className="mb-0 text-bold">{header.title}</p>
        {(hasValidata && data[index].content) ?
            Parser(data[index].content) :
            <p>{Language.getTranslation('factorNoResults')}</p>
        }
    </div>)
};

CollapsableInfoContainer.propTypes = {
    bgColor: PropTypes.string.isRequired,
    htmlId: PropTypes.string.isRequired,
    opened: PropTypes.bool,
    title: PropTypes.string.isRequired,
};
AlgemeneInfoContainer.propTypes = {
    familie: PropTypes.object.isRequired,
    functie: PropTypes.object.isRequired,
};
ResultaatgebiedContainer.propTypes = {
    data: PropTypes.array.isRequired,
};
Resultaatgebied.propTypes = {
    resultaatgebied: PropTypes.object.isRequired,
};
FactorContainer.propTypes = {
    data: PropTypes.array.isRequired,
    headers: PropTypes.array.isRequired,
    hideTextDifferences: PropTypes.bool,
};