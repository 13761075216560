import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Parser from "html-react-parser";
import HelperFunctions from "../global/HelperFunctions";
import Language from "../../language/Language";

export default class ContentPage extends Component {
    render() {
        const pageName = this.props.routerProps.match.params.pageName;
        const page = HelperFunctions.getItemFromArrayByValue(this.props.contentPages, 'slug', pageName);

        return (
            <div className="container my-5">
                {page !== undefined ? (
                    <div className="row">
                        <div className="col col-lg-8">
                            <h1>{page.title}</h1>
                            {Parser(page.content)}
                        </div>
                    </div>
                ) : (
                    <p>{Language.getTranslation('pageDoesNotExist', 'messages')}</p>
                )}
            </div>
        );
    }
}

ContentPage.propTypes = {
    contentPages: PropTypes.array.isRequired,
    routerProps: PropTypes.object.isRequired,
};