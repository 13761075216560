import React, {Component} from 'react';
import {BrowserRouter} from 'react-router-dom';

import './css/main.scss';
import history from './components/global/History';
import config from './config/config';
import Language from './language/Language';
import Routes from './components/Routes';
import Footer from "./components/global/Footer";
import Navbar from './components/global/Navbar';
import Api from "./components/api/Api";
import env from "./config/env.json";
import constants from "./config/constants.json";

class App extends Component {
    constructor(props) {
        super(props);
        document.title = config.metaTitle;

        this.state = {
            activeLanguage: Language.getLanguage(),
            contentPages: [],
        };
    }

    componentDidMount() {
        Api.getAllPages()
            .then(contentPages => this.setState({contentPages}))
            .catch(error => {
                env.environment === constants.environment.development && console.log('Error getting pages', error)
            });
    }

    // Enable this function when implementing multilanguage support
    // and use it in your code
    // changeLanguage(lang) {
    //     if (Language.setLanguage(lang)) {
    //         this.setState({ activeLanguage: lang }, function () {
    //             return lang;
    //         });
    //     }
    // }

    render() {
        const {contentPages}  = this.state;
        return (
            <div className="App">
                <BrowserRouter history={history}>
                    <Navbar contentPages={contentPages} />
                    {contentPages.length > 0 && <Routes contentPages={contentPages} />}
                    <Footer />
                </BrowserRouter>
            </div>
        );
    }
}

export default App;
