import Language from "../../language/Language";
import env from '../../config/env';
import constants from "../../config/constants";

const HelperFunctions = {
    getItemFromArrayByValue(arr, attr, value) {
        for (let i = 0, iLen = arr.length; i < iLen; i++) {
            if (arr[i][attr] === value) return arr[i];
        }
    },
    getTwoParametersFromUrl(param) {
        const value1 = this.getParameterFromUrl(param);
        const value2 = this.getParameterFromUrl(param + '/' + value1);
        return [value1, value2];
    },
    getParameterFromUrl(param) {
        let search = '/' + param + '/';
        if (!window.location.pathname.includes(search)) {
            return false;
        }
        let posStart = window.location.pathname.indexOf(search) + search.length; // locate the parameter in the url
        let posEnd = window.location.pathname.indexOf('/', posStart);
        if (posEnd === -1) {
            return window.location.pathname.slice(posStart);
        } else {
            return window.location.pathname.slice(posStart, posEnd);
        }
    },
    handleApiErrors(error, comment) {
        let errorCode;

        if (comment === undefined) {
            comment = '';
        }

        if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            // env.environment === constants.environment.development && console.log('data:', error.response.data);
            // env.environment === constants.environment.development && console.log('status:', error.response.status);
            // env.environment === constants.environment.development && console.log('headers:', error.response.headers);

            this.notifyOnError(error.response.status, false, comment);
            errorCode = error.response.status;
        } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
            // env.environment === constants.environment.development && console.log('request:', error.request);

            errorCode = 1000;
            this.notifyOnError(1000, true, error.request);
        } else {
            // Something happened in setting up the request that triggered an Error
            // env.environment === constants.environment.development && console.log('Error message:', error.message);

            errorCode = 1001;
            this.notifyOnError(1001, true, error.message);
        }

        return errorCode;
    },
    handleKeyPress(event, key, callback) {
        if (event.key === key) {
            callback();
        }
    },
    handleKeyPressRef(event, key, ref) {
        if (event.key === key) {
            ref.click();
        }
    },
    hasNumber(myString) {
        return /\d/.test(myString);
    },
    hasAlphabetical(myString) {
        return /[a-z]/i.test(myString);
    },
    isInt(value) {
        let x;
        return isNaN(value) ? !1 : (x = parseFloat(value), (0 | x) === x);
    },
    notifyOnError(statusCode, showUserMessage, additionalInfo) {
        const statusCodeTranslations = {
            '400': { userMessage: false, consoleMessage: 'Data not valid' },
            '401': { userMessage: false, consoleMessage: 'Invalid credentials' },
            '403': { userMessage: false, consoleMessage: 'Access denied' },
            '1000': { userMessage: 'applicationUnavailable', consoleMessage: 'API not reachable' },
            '1001': { userMessage: 'applicationUnavailable', consoleMessage: 'API request error' },
        };
        const userMessage = statusCodeTranslations.hasOwnProperty(statusCode) ? Language.getTranslation(statusCodeTranslations[statusCode].userMessage, 'messages') : Language.getTranslation('unhandledError', 'messages');
        const consoleMessage = statusCodeTranslations.hasOwnProperty(statusCode) ? statusCodeTranslations[statusCode].consoleMessage : Language.getTranslation('unhandledError', 'messages') + ', code ' + statusCode;

        if (showUserMessage) {
            console.log(userMessage);
        }

        if (env.environment === constants.environment.development) {
            console.log(consoleMessage);
            if (additionalInfo) {
                console.log('additional info: ', additionalInfo);
            }
        }
    }
};

export default HelperFunctions;
