import ApiConnector from "./ApiConnector";
import env from '../../config/env';

const Api = {
    getAllFamilies() {
        return new Promise((resolve, reject) => {
            const apiBaseUrl = getApiBaseUrl();
            if (apiBaseUrl) {
                ApiConnector.apiGet(apiBaseUrl + 'familie', false)
                    .then(data => {
                        resolve(data);
                    })
                    .catch(error => reject(error));
            } else {
                reject('No apiBaseUrl');
            }
        });
    },
    getAllPages() {
        return new Promise((resolve, reject) => {
            const apiBaseUrl = getApiBaseUrl();
            if (apiBaseUrl) {
                ApiConnector.apiGet(apiBaseUrl + 'page', false)
                    .then(data => resolve(data))
                    .catch(error => reject(error));
            } else {
                reject('No apiBaseUrl');
            }
        });
    },
    getSchaal(id) {
        return new Promise((resolve, reject) => {
            if (id === undefined) {
                reject('schaal id not found');
            }
            const apiBaseUrl = getApiBaseUrl();
            if (apiBaseUrl) {
                ApiConnector.apiGet(apiBaseUrl + 'schaal/' + id, false)
                    .then(data => {
                        resolve(data);
                    })
                    .catch(error => reject(error));
            } else {
                reject('No apiBaseUrl');
            }
        });
    },
};

function getApiBaseUrl() {
    if (env.apiBaseUrl === undefined) {
        return false;
    } else {
        return env.apiBaseUrl;
    }
}

export default Api;