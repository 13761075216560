import React, {Component, Fragment} from 'react';

import config from "../../config/config";
import Language from "../../language/Language";
import Api from "../api/Api";
import HelperFunctions from "../global/HelperFunctions";
import CollapsableInfoContainer, {
    AlgemeneInfoContainer,
    FactorContainer,
    ResultaatgebiedContainer
} from "./misc/CollapsableInfoContainer";
import CompareForm from "./CompareForm";
import LoadingSpinner from "../global/LoadingSpinner";
import history from '../global/History';

const CompareContainer = () => {
    return (
        <Fragment>
            <CompareForm redirectAfterFormSubmit={false} />
            <Compare />
        </Fragment>
    );
};

class Compare extends Component {
    constructor(props) {
        super(props);
        this.checkForSelectionChanges = this.checkForSelectionChanges.bind(this);
        this.refreshData = this.refreshData.bind(this);
        document.title = config.metaTitle + ' | ' + Language.getTranslation('comparePageTitle');

        this.state = {
            data: {
                startSchaal: null,
                endSchaal: null,
            },
            hasData: false,
            isLoading: false,
            selected: {
                startSchaal: null,
                endSchaal: null,
            },
        };

        // Listen for changes to the current location
        this.unlisten = history.listen(() => {
            this.checkForSelectionChanges();
        });
    }

    checkForSelectionChanges() {
        const selected = this.getSelectedSchalen();
        const {startSchaal, endSchaal} = this.state.selected;

        if (selected.startSchaal !== startSchaal || selected.endSchaal !== endSchaal) {
            this.setState({selected,}, () => this.refreshData());
        }
    }

    componentDidMount() {
        this.checkForSelectionChanges();
    }

    componentWillUnmount() {
        // Stop listening to changes for the current location
        this.unlisten();
    }

    getSelectedSchalen() {
        const selectedStart = HelperFunctions.getTwoParametersFromUrl('vergelijken');
        const selectedEnd = HelperFunctions.getTwoParametersFromUrl('to');
        return {
            startSchaal: parseInt(selectedStart[1]),
            endSchaal: parseInt(selectedEnd[1]),
        };
    }

    refreshData() {
        const {startSchaal, endSchaal} = this.state.selected;

        if (startSchaal && endSchaal) {
            this.setState({isLoading: true,});

            const promises = [
                new Promise((resolve, reject) => {
                    Api.getSchaal(startSchaal)
                        .then(data => resolve(data))
                        .catch(error => reject(error));
                }),
                new Promise((resolve, reject) => {
                    Api.getSchaal(endSchaal)
                        .then(data => resolve(data))
                        .catch(error => reject(error));
                }),
            ];

            // Get data from api
            Promise.all(promises)
                .then(result => {
                    const data = {
                        startSchaal: result[0].number < result[1].number ? result[0] : result[1],
                        endSchaal: result[1].number > result[0].number ? result[1] : result[0],
                    };

                    this.setState({
                        data,
                        isLoading: false,
                    });
                })
                .catch(error => {
                    console.log('fout bij het ophalen van de schalen', error);
                    this.setState({isLoading: false,});
                });
        }
    }

    render() {
        const {startSchaal, endSchaal} = this.state.data;

        if (this.state.isLoading) {
            return <LoadingSpinner loadingMessage={true} />
        }

        if (this.state.selected.startSchaal && this.state.selected.endSchaal && startSchaal && endSchaal) {
            return (
                <Fragment>
                    <div className="container">
                        <div className="row gutter-2 border-between-md border-between-2 border-between-dashed">
                            <div className="col-12 col-md-6">
                                <h1 className={'mt-4 text-uppercase text-' + startSchaal.familie.color}>{startSchaal.familie.title} | {startSchaal.functie.reeksTitle}</h1>
                                <p className="lead"><strong>{Language.getTranslation('schaal')} {startSchaal.number}</strong></p>
                                <p><strong>{Language.getTranslation('functieTypering')}</strong><br />{startSchaal.korteTypering}</p>
                            </div>
                            <div className="col-12 col-md-6">
                                <h1 className={'mt-4 text-uppercase text-' + endSchaal.familie.color}>{endSchaal.familie.title} | {endSchaal.functie.reeksTitle}</h1>
                                <p className="lead"><strong>{Language.getTranslation('schaal')} {endSchaal.number}</strong></p>
                                <p><strong>{Language.getTranslation('functieTypering')}</strong><br />{endSchaal.korteTypering}</p>
                            </div>
                        </div>
                    </div>

                    <CollapsableInfoContainer bgColor="light" htmlId="jsFunctieInfoAlgemeen" opened={true} title={Language.getTranslation('algemeneInformatie')}>
                        {startSchaal.familie.id === endSchaal.familie.id ? (
                            <AlgemeneInfoContainer functie={startSchaal.functie} familie={startSchaal.familie} />
                        )  : (
                            <div className="row gutter-2 border-between-md border-between-2 border-between-dashed">
                                <div className="col-12 col-md-6 py-md-3">
                                    <AlgemeneInfoContainer functie={startSchaal.functie} familie={startSchaal.familie} />
                                </div>
                                <div className="col-12 col-md-6 py-md-3">
                                    <AlgemeneInfoContainer functie={endSchaal.functie} familie={endSchaal.familie} />
                                </div>
                            </div>
                        )}
                    </CollapsableInfoContainer>

                    <CollapsableInfoContainer bgColor="white" htmlId="jsFunctieResultaatgebieden" title={Language.getTranslation('resultaatgebieden')}>
                        {startSchaal.familie.id === endSchaal.familie.id ? (
                            <ResultaatgebiedContainer data={startSchaal.resultaatgebieden} />
                        )  : (
                            <div className="row gutter-2 border-between-md border-between-2 border-between-dashed">
                                <div className="col-12 col-md-6 py-md-3">
                                    <ResultaatgebiedContainer data={startSchaal.resultaatgebieden} />
                                </div>
                                <div className="col-12 col-md-6 py-md-3">
                                    <ResultaatgebiedContainer data={endSchaal.resultaatgebieden} />
                                </div>
                            </div>
                        )}
                    </CollapsableInfoContainer>

                    <CollapsableInfoContainer bgColor="light" htmlId="jsFunctieFactoren" title={Language.getTranslation('factoren')}>
                        <div className="row gutter-2 border-between-md border-between-2 border-between-dashed">
                            <div className="col-12 col-md-6 py-md-3">
                                <FactorContainer data={startSchaal.factoren} headers={startSchaal.factorHeaders} hideTextDifferences={true} />
                            </div>
                            <div className="col-12 col-md-6 py-md-3">
                                <FactorContainer data={endSchaal.factoren} headers={endSchaal.factorHeaders} />
                            </div>
                        </div>
                    </CollapsableInfoContainer>

                    <CollapsableInfoContainer bgColor="white" htmlId="jsFunctieFuwaprov" title={Language.getTranslation('fuwaprov')}>
                        {startSchaal.fuwaprov === endSchaal.fuwaprov ? (
                            <p>{startSchaal.fuwaprov}</p>
                        ) : (
                            <div className="row gutter-2 border-between-md border-between-2 border-between-dashed">
                                <div className="col-12 col-md-6 py-md-3">
                                    <p className="m-0">{startSchaal.fuwaprov}</p>
                                </div>
                                <div className="col-12 col-md-6 py-md-3">
                                    <p className="m-0">{endSchaal.fuwaprov}</p>
                                </div>
                            </div>
                        )}
                    </CollapsableInfoContainer>
                </Fragment>
            );
        } else {
            return null;
        }
    }
}

export default CompareContainer;